/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                $('#navbarResponsive').on('hide.bs.collapse', function () {
                    $('.sticky-header').removeClass('disabled');
                });

                $('#navbarResponsive').on('show.bs.collapse', function () {
                    $('.sticky-header').addClass('disabled');
                });

                $('.block-selector-group .block-selector').on('click', function () {
                    if (!$(this).hasClass('selected')) {
                        $(this).parent().find('.block-selector').toggleClass('selected');

                        var value = ($(this).data('value'));
                        $('#' + value).prop('checked', true).trigger("change");
                    }
                });

                $('.block-link-content').matchHeight();
                $('.info-block-inner').matchHeight();


                /*
                  Youtube Video Feature Modal Logic

                  Loads in youtube embed on first modal open, then auto plays and pauses upon show/hide.
                 */
                var videoSrcDefault = '';
                var videoSrcLoad = '';

                $('#videoFeatureModal').on('shown.bs.modal', function () {
                    $(this).find('.yt_player_iframe').each(function () {
                        videoSrcDefault = $(this).attr('src');

                        if (typeof videoSrcDefault === 'undefined' || videoSrcDefault === "") {
                            videoSrcLoad = $(this).data('video-src');
                            $(this).attr('src', videoSrcLoad);
                        } else {
                            this.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
                        }
                    });
                })

                $('#videoFeatureModal').on('hide.bs.modal', function () {
                    $(this).find('.yt_player_iframe').each(function () {
                        this.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
                    });
                });

                // Tracks Contact Form 7 form submissions by form ID
                $(document).on('wpcf7mailsent', function ( event ) {
                    if ( typeof event.detail.contactFormId !== 'undefined' ) {
                        ga('send', 'event', 'Form', 'Submit', event.detail.contactFormId);
                    }
                });
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        },
        // Property Listings page
        'property_listings': {
            init: function () {
                // JavaScript to be fired on the property listings page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
